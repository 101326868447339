/* eslint-disable */
const trasazielona =  [
  [51.254523, 17.099250],
  [51.253765, 17.098913],
  [51.251880, 17.096996],
  [51.250307, 17.095238],
  [51.250220, 17.095007],
  [51.249805, 17.094128],
  [51.248289, 17.089378],
  [51.248494, 17.086430],
  [51.248363, 17.082803],
  [51.248185, 17.081236],
  [51.248830, 17.078862],
  [51.250390, 17.078632],
  [51.250840, 17.078288],
  [51.252418, 17.076143],
  [51.254265, 17.073686],
  [51.258260, 17.070027],
  [51.258622, 17.069383],
  [51.260401, 17.062989],
  [51.260636, 17.061787],
  [51.260905, 17.061766],
  [51.263067, 17.057292],
  [51.263248, 17.056777],
  [51.263577, 17.056241],
  [51.264141, 17.054910],
  [51.264893, 17.053880],
  [51.265182, 17.053182],
  [51.265417, 17.052453],
  [51.265370, 17.051305],
  [51.265612, 17.049953],
  [51.265954, 17.049084],
  [51.264786, 17.048601],
  [51.262423, 17.047979],
  [51.262611, 17.046906],
  [51.263443, 17.044138],
  [51.264034, 17.040930],
  [51.264491, 17.036478],
  [51.264799, 17.035705],
  [51.266746, 17.033903],
  [51.267585, 17.033677],
  [51.268424, 17.033720],
  [51.268309, 17.034176],
  [51.268175, 17.035656],
  [51.268181, 17.036429],
  [51.268591, 17.035742],
  [51.268980, 17.035485],
  [51.269376, 17.035560],
  [51.270611, 17.036504],
  [51.271001, 17.036633],
  [51.271350, 17.036504],
  [51.272101, 17.035624],
  [51.272565, 17.035495],
  [51.273068, 17.035796],
  [51.273820, 17.036332],
  [51.274249, 17.036493],
  [51.274699, 17.036332],
  [51.275129, 17.035957],
  [51.276122, 17.035560],
  [51.276397, 17.035420],
  [51.276961, 17.034551],
  [51.278103, 17.033441],
  [51.278503, 17.033417],
  [51.279422, 17.034000],
  [51.279603, 17.034056],
  [51.279906, 17.033992],
  [51.280161, 17.033782],
  [51.280472, 17.033214],
  [51.280678, 17.032476],
  [51.280839, 17.031742],
  [51.281085, 17.030297],
  [51.281018, 17.029631],
  [51.280829, 17.029162],
  [51.280338, 17.028365],
  [51.280045, 17.027710],
  [51.279643, 17.026694],
  [51.279526, 17.026273],
  [51.279541, 17.025614],
  [51.279726, 17.025684],
  [51.281019, 17.025604],
  [51.281856, 17.025413],
  [51.282311, 17.025258],
  [51.282937, 17.025258],
  [51.283913, 17.025209],
];
export default trasazielona;
