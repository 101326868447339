<template>
  <div class="page-wrapper">
    <transition name="fade">
      <div v-if="showSidebar" id="menu" class="sidebar">
        <i
          class="far fa-times-circle closeButton"
          @click="showSidebar = false"
        ></i>
        <div class="objectData">
          <img
            v-show="showImg"
            :src="currentObject.images[0]"
            width="100%"
            @load="onImgLoad"
          />
          <br /><br />
          <h2>{{ currentObject.name }}</h2>
          <p v-html="currentObject.description"></p>
          <br /><br />
          <b-button
            variant="outline-primary"
            href="#"
            target="_blank"
            class="mr-1"
          >
            <b-icon-book></b-icon-book> Czytaj więcej
          </b-button>
          <b-button
            variant="outline-primary"
            :href="currentObject.googleMapsUrl"
            target="_blank"
          >
            <b-icon-map></b-icon-map> Nawiguj
          </b-button>
        </div>
      </div>
    </transition>
    <l-map
      :zoom="zoom"
      :center="center"
      style="width:100%; height: 100%"
      :options="mapOptions"
    >
      <l-marker
        v-for="point in points"
        :key="point.id"
        :lat-lng="point.coordinates"
        :icon="point.icon"
        @click="showPoint(point.id)"
      />
      <v-tilelayer-googlemutant
        apikey="AIzaSyBWKOcsGaeQngpGkOkxzFP4v5GMW0nqtJQ"
        lang="pl"
        region="pl"
        :options="googlemutantoptionsobject"
      >
      </v-tilelayer-googlemutant>
      <l-polygon
        :lat-lngs="boundary"
        :fill="false"
        weight="2"
        color="#ec0031"
        opacity="0.85"
      >
      </l-polygon>
      <l-polyline :lat-lngs="trasazielona" color="green" opacity="0.5" dashArray="10, 10"></l-polyline>
      <l-polyline :lat-lngs="trasaniebieska" color="blue" opacity="0.5" dashArray="10, 10"></l-polyline>

    </l-map>
  </div>
</template>

<script>
/* eslint-disable */
import PointDB from '../firebase/point-db'
import {
  LMap,
  LTileLayer,
  LMarker,
  LIcon,
  LGeoJson,
  LGridLayer
} from 'vue2-leaflet'
import { latLng, icon } from 'leaflet'
import kml from '../../kml.json'
import Vue2LeafletGoogleMutant from 'vue2-leaflet-googlemutant'
import geodata from '../assets/geodata_parsed.json'
import { VueperSlides, VueperSlide } from 'vueperslides'
import MD5 from 'crypto-js/md5'
import trasazielona from "@/views/trasazielona"
import granicagminy from "@/views/granicagminy"
import trasaniebieska from "@/views/trasaniebieska"


const defaultMarker =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAApCAYAAADAk4LOAAAFgUlEQVR4Aa1XA5BjWRTN2oW17d3YaZtr2962HUzbDNpjszW24mRt28p47v7zq/bXZtrp/lWnXr337j3nPCe85NcypgSFdugCpW5YoDAMRaIMqRi6aKq5E3YqDQO3qAwjVWrD8Ncq/RBpykd8oZUb/kaJutow8r1aP9II0WmLKLIsJyv1w/kqw9Ch2MYdB++12Onxee/QMwvf4/Dk/Lfp/i4nxTXtOoQ4pW5Aj7wpici1A9erdAN2OH64x8OSP9j3Ft3b7aWkTg/Fm91siTra0f9on5sQr9INejH6CUUUpavjFNq1B+Oadhxmnfa8RfEmN8VNAsQhPqF55xHkMzz3jSmChWU6f7/XZKNH+9+hBLOHYozuKQPxyMPUKkrX/K0uWnfFaJGS1QPRtZsOPtr3NsW0uyh6NNCOkU3Yz+bXbT3I8G3xE5EXLXtCXbbqwCO9zPQYPRTZ5vIDXD7U+w7rFDEoUUf7ibHIR4y6bLVPXrz8JVZEql13trxwue/uDivd3fkWRbS6/IA2bID4uk0UpF1N8qLlbBlXs4Ee7HLTfV1j54APvODnSfOWBqtKVvjgLKzF5YdEk5ewRkGlK0i33Eofffc7HT56jD7/6U+qH3Cx7SBLNntH5YIPvODnyfIXZYRVDPqgHtLs5ABHD3YzLuespb7t79FY34DjMwrVrcTuwlT55YMPvOBnRrJ4VXTdNnYug5ucHLBjEpt30701A3Ts+HEa73u6dT3FNWwflY86eMHPk+Yu+i6pzUpRrW7SNDg5JHR4KapmM5Wv2E8Tfcb1HoqqHMHU+uWDD7zg54mz5/2BSnizi9T1Dg4QQXLToGNCkb6tb1NU+QAlGr1++eADrzhn/u8Q2YZhQVlZ5+CAOtqfbhmaUCS1ezNFVm2imDbPmPng5wmz+gwh+oHDce0eUtQ6OGDIyR0uUhUsoO3vfDmmgOezH0mZN59x7MBi++WDL1g/eEiU3avlidO671bkLfwbw5XV2P8Pzo0ydy4t2/0eu33xYSOMOD8hTf4CrBtGMSoXfPLchX+J0ruSePw3LZeK0juPJbYzrhkH0io7B3k164hiGvawhOKMLkrQLyVpZg8rHFW7E2uHOL888IBPlNZ1FPzstSJM694fWr6RwpvcJK60+0HCILTBzZLFNdtAzJaohze60T8qBzyh5ZuOg5e7uwQppofEmf2++DYvmySqGBuKaicF1blQjhuHdvCIMvp8whTTfZzI7RldpwtSzL+F1+wkdZ2TBOW2gIF88PBTzD/gpeREAMEbxnJcaJHNHrpzji0gQCS6hdkEeYt9DF/2qPcEC8RM28Hwmr3sdNyht00byAut2k3gufWNtgtOEOFGUwcXWNDbdNbpgBGxEvKkOQsxivJx33iow0Vw5S6SVTrpVq11ysA2Rp7gTfPfktc6zhtXBBC+adRLshf6sG2RfHPZ5EAc4sVZ83yCN00Fk/4kggu40ZTvIEm5g24qtU4KjBrx/BTTH8ifVASAG7gKrnWxJDcU7x8X6Ecczhm3o6YicvsLXWfh3Ch1W0k8x0nXF+0fFxgt4phz8QvypiwCCFKMqXCnqXExjq10beH+UUA7+nG6mdG/Pu0f3LgFcGrl2s0kNNjpmoJ9o4B29CMO8dMT4Q5ox8uitF6fqsrJOr8qnwNbRzv6hSnG5wP+64C7h9lp30hKNtKdWjtdkbuPA19nJ7Tz3zR/ibgARbhb4AlhavcBebmTHcFl2fvYEnW0ox9xMxKBS8btJ+KiEbq9zA4RthQXDhPa0T9TEe69gWupwc6uBUphquXgf+/FrIjweHQS4/pduMe5ERUMHUd9xv8ZR98CxkS4F2n3EUrUZ10EYNw7BWm9x1GiPssi3GgiGRDKWRYZfXlON+dfNbM+GgIwYdwAAAAASUVORK5CYII='

const defaultMarkerShadow =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACkAAAApCAQAAAACach9AAACMUlEQVR4Ae3ShY7jQBAE0Aoz/f9/HTMzhg1zrdKUrJbdx+Kd2nD8VNudfsL/Th///dyQN2TH6f3y/BGpC379rV+S+qqetBOxImNQXL8JCAr2V4iMQXHGNJxeCfZXhSRBcQMfvkOWUdtfzlLgAENmZDcmo2TVmt8OSM2eXxBp3DjHSMFutqS7SbmemzBiR+xpKCNUIRkdkkYxhAkyGoBvyQFEJEefwSmmvBfJuJ6aKqKWnAkvGZOaZXTUgFqYULWNSHUckZuR1HIIimUExutRxwzOLROIG4vKmCKQt364mIlhSyzAf1m9lHZHJZrlAOMMztRRiKimp/rpdJDc9Awry5xTZCte7FHtuS8wJgeYGrex28xNTd086Dik7vUMscQOa8y4DoGtCCSkAKlNwpgNtphjrC6MIHUkR6YWxxs6Sc5xqn222mmCRFzIt8lEdKx+ikCtg91qS2WpwVfBelJCiQJwvzixfI9cxZQWgiSJelKnwBElKYtDOb2MFbhmUigbReQBV0Cg4+qMXSxXSyGUn4UbF8l+7qdSGnTC0XLCmahIgUHLhLOhpVCtw4CzYXvLQWQbJNmxoCsOKAxSgBJno75avolkRw8iIAFcsdc02e9iyCd8tHwmeSSoKTowIgvscSGZUOA7PuCN5b2BX9mQM7S0wYhMNU74zgsPBj3HU7wguAfnxxjFQGBE6pwN+GjME9zHY7zGp8wVxMShYX9NXvEWD3HbwJf4giO4CFIQxXScH1/TM+04kkBiAAAAAElFTkSuQmCC'

export default {
  head() {
    return {
      title: {
        inner: 'Home'
      },
      meta: [
        {
          name: 'description',
          content: `${this.appTitle} home page`,
          id: 'desc'
        }
      ]
    }
  },
  // computed: mapState('app', ['appTitle']),
  async mounted() {
    const pointDb = new PointDB()

    const news = await pointDb.readAll()
  },
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
    LGeoJson,
    LGridLayer,
    'v-tilelayer-googlemutant': Vue2LeafletGoogleMutant,
    VueperSlides,
    VueperSlide
  },
  data() {
    let centerLatLng;
    let defaultZoom;
    if (window.screen.availWidth > 1900) {
      centerLatLng = latLng(51.22, 17.03);
      defaultZoom = 12;
    } else {
      centerLatLng = latLng(51.22, 17.03);
      defaultZoom = 11;
    }
    // console.log(centerLatLng);
    return {
      zoom: defaultZoom,
      // center: latLng(51.2235171,17.0359915),
      // center: latLng(51.232600725584106, 17.038738048728106),
      // center: latLng(51.2081192,16.9911741),
      // center: latLng(51.23, 17.0), // pc
      // center: latLng(51.20, 17.03), // mobile 1
      // center: latLng(51.17, 17.03), // mobile 2
      center: centerLatLng,
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      staticAnchor: [16, 37],
      // customText: "Foobar",
      iconSize: 64,
      googlemutantoptionsobject: {
        zoomSnap: 0.1
      },
      showSidebar: false,
      showImg: false,
      currentObject: {},
      mapOptions: {
        zoomSnap: 0.1
        // maptype: 'satellite'
      },
      trasazielona: trasazielona,
      trasaniebieska: trasaniebieska,

      boundary: granicagminy
    }
  },
  computed: {
    dynamicSize() {
      // return [this.iconSize, this.iconSize * 1.15]
    },
    dynamicAnchor() {
      // return [this.iconSize / 2, this.iconSize * 1.15]
    }
  },
  methods: {
    showPoint(id) {
      let obj = this.points.find(item => item.id === id)
      this.currentObject = obj
      this.currentObject.googleMapsUrl =
        'http://maps.google.com/maps?daddr=' +
        obj.coordinates[0] +
        ',' +
        obj.coordinates[1]
      this.currentObject.googleMapsNavi =
        'google.navigation:q' + obj.coordinates[0] + ',' + obj.coordinates[1]
      this.showImg = true
      this.showSidebar = true
    },
    onImgLoad() {
      this.showImg = true
    }
  },
  async created() {
    // LGridLayer.();

    let linkify = inputText => {
      // return inputText;
      let replacedText, replacePattern1, replacePattern2, replacePattern3

      //URLs starting with http://, https://, or ftp://
      replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim
      replacedText = inputText.replace(
        replacePattern1,
        '<a href="$1" target="_blank">$1</a>'
      )

      //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
      replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim
      replacedText = replacedText.replace(
        replacePattern2,
        '$1<a href="http://$2" target="_blank">$2</a>'
      )

      //Change email addresses to mailto:: links.
      replacePattern3 = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim
      replacedText = replacedText.replace(
        replacePattern3,
        '<a href="mailto:$1">$1</a>'
      )

      return replacedText
    }

    this.points = []
    let i = 1
    for (let featureCollection of [geodata]) {
      for (let feature of featureCollection.features) {
        let images = feature.properties.photos;
          // ? feature.properties.gx_media_links.split(' ')
          // : []
        // console.log(images);
        if (images.length > 0) {
          const mini = images.map(
              url =>
                  '/img/map-icons/' +
                  images[0] +
                  // MD5(url.replace('?fife', '').match('[^\/]+$')[0]).toString() +
                  '.png'
          )
          // const mini = ['/img/iconsm/61ca73e00e3990c94bc4df8eac72a38e.png'];
          images = images.map(
              url =>
                  '/img/map-photos/' +
                  images[0] +
                  // MD5(url.replace('?fife', '').match('[^\/]+$')[0]).toString() +
                  '.png'
          )
          let icon = new L.Icon({
            iconUrl: mini[0] ? mini[0] : defaultMarker,
            // iconAnchor: [12, 41],
            shadowUrl: defaultMarkerShadow,
            className: 'photoMarker',
            // iconSize: [32, 32],
            // className: feature.properties.gx_media_links
            //     ? 'photoMarker'
            //     : 'testClass'
          })
          // images.map((url) => './public/img/pictures/' + url.replace('?fife', '').match('[^\/]+$'));
          this.points.push({
            id: i++,
            coordinates: [
              feature.geometry.coordinates[1],
              feature.geometry.coordinates[0]
            ],
            icon,
            name: feature.properties.name,
            description: linkify(
                feature.properties.description.replace(/<[^>]*>?/gm, '')
            ),
            images: [images[0]]
          })
        }
        // const mini = [];

      }
    }
  }
}
</script>
<style>
.sidebar {
  width: 450px;
  position: fixed;
  top: 90px;
  left: 0;
  /*top: 7vh;;*/
  bottom: 0;
  z-index: 9999;
  background: #fff;
  padding: 20px;
  /*margin-top: 60px;*/
  overflow-y: auto;
}
#nav-collapse {
  /*margin-left: -20px;*/
  /*margin-right: -20px;*/
  padding: 20px;

  position: fixed;
  top: 90px;
  left: 0px;
  /* bottom: 0px; */
  width: 100%;
}
@media only screen and (min-width: 340px) {
  .sidebar {
    width: 320px;
  }

}
/* Medium Devices, Desktops */
@media only screen and (min-width: 992px) {
  .sidebar {
    top: 110px;
    width: 450px;
  }
  #nav-collapse {
    position: static;
  }
}
.closeButton {
  position: absolute;
  right: 15px;
  cursor: pointer;
  font-size: 25px;
}
.objectData {
  padding-top: 30px;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
