<template>
  <div class="page-wrapper container">
    <h1>Wycieczki</h1>
    <p>Propozycje lokalnych wypraw rowerowych - strona w budowie</p>
  </div>
</template>

<script>
/* eslint-disable */

export default {
  head() {
    return {
      title: {
        inner: 'Wycieczki'
      },
      meta: [
        {
          name: 'description',
          content: `${this.appTitle} home page`,
          id: 'desc'
        }
      ]
    }
  },
  async mounted() {},
  components: {},
  data() {
    return {}
  },
  computed: {},
  methods: {},
  async created() {}
}
</script>
<style lang="scss">
</style>
