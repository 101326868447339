const trasaniebieska =  [
  [51.165599, 17.047435],
  [51.165567, 17.047193],
  [51.165607, 17.047034],
  [51.166260, 17.046475],
  [51.166523, 17.046380],
  [51.167208, 17.046393],
  [51.167782, 17.045897],
  [51.168407, 17.042943],
  [51.169168, 17.039137],
  [51.169981, 17.035135],
  [51.170606, 17.033502],
  [51.170662, 17.032835],
  [51.171291, 17.031260],
  [51.171674, 17.030078],
  [51.171741, 17.029690],
  [51.171797, 17.028604],
  [51.171897, 17.027842],
  [51.172323, 17.026361],
  [51.172598, 17.026082],
  [51.173096, 17.025192],
  [51.173772, 17.024739],
  [51.175814, 17.024698],
  [51.176415, 17.024714],
  [51.177654, 17.025034],
  [51.178363, 17.024468],
  [51.178466, 17.023518],
  [51.177795, 17.023478],
  [51.177809, 17.023391],
  [51.178350, 17.023261],
  [51.178628, 17.023372],
  [51.179003, 17.023692],
  [51.181010, 17.026032],
  [51.181488, 17.025389],
  [51.181733, 17.024693],
  [51.181958, 17.024195],
  [51.183994, 17.020831],
  [51.184084, 17.020764],
  [51.185300, 17.018305],
  [51.185843, 17.017091],
  [51.186058, 17.016929],
  [51.187307, 17.016005],
  [51.187680, 17.015908],
  [51.188598, 17.016060],
  [51.189474, 17.015853],
  [51.190506, 17.015531],
  [51.190894, 17.015282],
  [51.191237, 17.015094],
  [51.191991, 17.014341],
  [51.192486, 17.013107],
  [51.192798, 17.011825],
  [51.193209, 17.010841],
  [51.193868, 17.010027],
  [51.194549, 17.008569],
  [51.194721, 17.008484],
  [51.195932, 17.005993],
  [51.196860, 17.004067],
  [51.197077, 17.003812],
  [51.198029, 17.003204],
  [51.198296, 17.002755],
  [51.198444, 17.002159],
  [51.198402, 17.000865],
  [51.198231, 17.000294],
  [51.198753, 16.997791],
  [51.199293, 16.995683],
  [51.200165, 16.994777],
  [51.201397, 16.994331],
  [51.201666, 16.993918],
  [51.202243, 16.992842],
  [51.202969, 16.990829],
  [51.204236, 16.985496],
  [51.205230, 16.981131],
  [51.205628, 16.979928],
  [51.205874, 16.978438],
  [51.206136, 16.975380],
  [51.206305, 16.974936],
  [51.206993, 16.970927],
  [51.207381, 16.968583],
  [51.207648, 16.968030],
  [51.207930, 16.966596],
  [51.208273, 16.965456],
  [51.208449, 16.964673],
];
export default trasaniebieska;
