<template>
  <div class="page-wrapper container gallery">
    <h1>Historia</h1>
    <h3>Pałace</h3>
    <figure class="figure">
      <h5>Pałac w Machnicach</h5>
      <img
        alt="pałac w Machnicach"
        src="/img/photos/hist_palac_machnice.jpg"
        class="figure-img img-fluid rounded text-center"
        style="width: 500px; margin: auto"
      />
      <p>
        Malowniczy park i pałac są ozdobą miejscowości Machnice. W pobliżu
        pałacu znajduje się Stary Folwark – przestronny kompleks
        sportowo-rekreacyjny oferujący gościom bazę sportową, miejsca noclegowe,
        restaurację, sale bankietowe i konferencyjne.
        <a href="http://www.ptakmachnice.pl/stary-folwark.html" target="_blank">
          http://www.ptakmachnice.pl/stary-folwark.html
        </a>
      </p>
    </figure>
    <br />

    <figure class="figure">
      <h5>Pałac w Wiszni Małej</h5>
      <img
        alt="pałac w Wiszni Małej"
        src="/img/photos/hist_palac_wisznia_mala.jpg"
        class="figure-img img-fluid rounded"
        style="width: 500px;"
      />
      <p>
        Barokowa siedziba szlachecka w Wiszni Małej istniała już w XVIII wieku.
        Po 1836 roku w jej miejscu nowy właściciel majątku Eduard Friedrich von
        Löbbecke ufundował klasycystyczną rezydencję. Założony na planie
        prostokąta murowany obiekt, ozdobiony czterokolumnowym portykiem,
        przetrwał II wojnę światową i stał się siedzibą szkoły.
      </p>
    </figure>

    <br />

    <figure class="figure">
      <h5>Pałac w Strzeszowie</h5>
      <img
        alt="pałac w Strzeszowie"
        src="/img/photos/hist_strzeszow_palac.jpg"
        class="figure-img img-fluid rounded"
        style="width: 500px;"
      />
      <p>
        Pałac jest częścią zespołu na obszarze dziesięciu hektarów zabytkowego
        parku otoczonego malownicza fosą. Barokowy obiekt został wzniesiony ok.
        1567 r. prawdopodobnie na miejscu istniejącego tu wcześniej drewnianego
        dworu obronnego pamiętającego czasy Henryka Brodatego. Na początku XX w.
        został powiększony i przebudowany. W 1945 r. dwór, określany jako pałac
        Rhedigerów, stał się siedzibą kwatery głównej wojsk radzieckich. Obecnie
        jest własnością prywatną.
      </p>
    </figure>
    <br />

    <h3>Kolej wąskotorowa z Trzebnicy do Wrocławia</h3>
    <figure class="figure">
      <img
        src="/img/photos/hist_bud_gminy.png"
        class="figure-img img-fluid rounded"
        style="width: 500px;"
      />
    </figure>
    <p>
      Wrocławsko-Trzebnicko-Prusicka KW
    </p>
    <p>
      Pierwszy pociąg do Trzebnicy, wyruszył ze stacji Wrocław Różanka
      1.06.1898r, zaś do Prusic z dniem 5 października. Ruch z placu Staszica
      uruchomiono dokładnie trzy miesiące później - 5.01.1899r.
    </p>
    <br />
    <figure class="figure">
      <img
        src="/img/photos/ws.png"
        class="figure-img img-fluid rounded"
        style="width: 500px;"
      />
    </figure>

    <p>
      15 grudnia 1950r. dworzec Wrocław Wąskotorowy został przeniesiony na
      terytorium dworca towarowego, zaś pomieszczenia odpraw pasażerów urządzono
      na pobliskiej stacji Wrocław Karłowice, zmieniając jej nazwę na Wrocław
      Wąskotorowy.
    </p>
    <p>
      Przebieg trasy kolei wąskotorowej Wrocław - Trzebnica - Prusice
    </p>
    <figure class="figure">
      <img
        src="/img/photos/rozkladjazdy.jpg"
        class="figure-img img-fluid rounded"
        style="width: 500px;"
      />
    </figure>
    <p>
      Niespełna 17 lat później, 27.05.1967r. odcinek z Wrocławia do Trzebnicy
      został zlikwidowany. Wagonem, który wykonał ostatni planowy rejs z
      Wrocławia do Trzebnicy Gaj był MBXD1-115.
    </p>
    <figure class="figure">
      <img
        src="/img/photos/ws2.png"
        class="figure-img img-fluid rounded"
        style="width: 500px;"
      />
    </figure>
    <p>
      Obecnie fragment trasy kolei wąskotorowej przebiegający przez wzgórza
      Trzebnickie z Piotrkowiczek do Węgrzynowa służy m.in. jako trasa rowerowa,
      z której Turyści podziwiać mogą panoramę Wrocławia i okolic, a przy dobrej
      widoczności nawet Karkonosze oddalone o ok 100km.
    </p>
    <figure class="figure">
      <img
        src="/img/photos/wsc.png"
        class="figure-img img-fluid rounded"
        style="width: 500px;"
      />
    </figure>

    <h3>Kościoły</h3>

    <figure class="figure">
      <h5>Kościół w Krynicznie</h5>
      <img
        alt="zdjecie prezentuje kościół w Krynicznie"
        src="/img/photos/hist_kosciol_kryniczno.png"
        class="figure-img img-fluid rounded"
        style="width: 500px;"
      />
      <p>
        Kościół parafialny pw. św. Stanisława Biskupa i Męczennika został
        wzniesiony w XV wieku. Na jego miejscu w XVI wieku postawiono budynek z
        surowej cegły. Cennym elementem wystroju wnętrza są organy zbudowane w
        1746 r.
      </p>
    </figure>
    <br />

    <figure class="figure">
      <h5>
        Kościół w Strzeszowie
      </h5>
      <img
        alt="kościół w Strzeszowie"
        src="/img/photos/hist_kosciol_strzeszow.png"
        class="figure-img img-fluid rounded"
        style="width: 500px;"
      />
      <p>
        Kościół filialny pw. Podwyższenia Krzyża Świętego wzniesiony
        prawdopodobnie w 1374 roku. Znacznie zniszczony został odbudowany pod
        koniec XV wieku, a następnie odrestaurowany w wieku XIX.
      </p>
    </figure>
    <br />

    <figure class="figure">
      <h5>
        Kościół w Piotrkowiczkach
      </h5>
      <img
        alt="kościół w Piotrkowiczkach"
        src="/img/photos/hist_kosciol_piotrkowiczki.jpg"
        class="figure-img img-fluid rounded"
        style="width: 500px;"
      />
      <p>
        Kościół filialny p.w. Najświętszego Serca Jezusowego w Piotrkowiczkach
        został wzniesiony na początku XV wieku, a następnie w XIX wieku
        przebudowany. Obok kościoła stoi drewniana dzwonnica postawiona na pocz.
        XIX w.
      </p>
    </figure>
    <br />

    <figure class="figure">
      <h5>
        Kościół w Wysokim Kościele
      </h5>
      <img
        alt="zdjecie prezentuje kościół w Wysokim Kościele"
        src="/img/photos/hist_kosciol_wk.jpg"
        class="figure-img img-fluid rounded"
        style="width: 500px;"
      />
      <p>
        Kościół pw. Niepokalanego Serca NMP w Wysokim Kościele został wzniesiony
        w 1828 roku na miejscu drewnianego kościoła z 1335 roku. Do końca II
        wojny światowej był to kościół protestancki.
      </p>
    </figure>
    <br />

    <figure class="figure">
      <h5>Kościół w Szewcach</h5>
      <img
        alt="kościół w Strzeszowie"
        src="/img/photos/hist_kosciol_szewce.png"
        class="figure-img img-fluid rounded"
        style="width: 500px;"
      />
      <p>
        Kościół parafialny p.w. św. Anny w Szewcach został wzniesiony w XIV
        wieku, a następnie przebudowany w XVIII w. We wnętrzu znajduje się
        barokowy ołtarz z początku XVIII w. z obrazem św. Anny, patronki
        kościoła pędzla F. A. Schefflera z 1740 roku oraz renesansowa
        chrzcielnica z początku XVII w.
      </p>
    </figure>
    <br>
    <figure class="figure">
      <img
          src="/img/photos/wm_hist.jpg"
          class="figure-img img-fluid rounded"
          style="width: 500px;"
      />
    </figure>
    <hr>
    <div class="footer">
      <div class="row">
        <div class="col-sm">
          Urząd Gminy Wisznia Mała <br>
          Ul. Wrocławska 9 <br>
          55-114 Wisznia Mała <br>
          Tel. 71 308 48 00 <br>
          71 312 70 25 <br>
        </div>
        <div class="col-sm">
          <br><br>
          e-mail: ug@wiszniamala.pl <br>
          <a href="www.wiszniamala.pl">www.wiszniamala.pl</a> <br>
          <a href="www.facebook.com/wiszniamala">facebook.com/wiszniamala</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

export default {
  head() {
    return {
      title: {
        inner: 'Historia'
      },
      meta: [
        {
          name: 'description',
          content: `${this.appTitle} home page`,
          id: 'desc'
        }
      ]
    }
  },
  async mounted() {},
  components: {},
  data() {
    return {}
  },
  computed: {},
  methods: {},
  async created() {}
}
</script>
<style lang="scss">

</style>
