<template>
  <div class="page-wrapper container gallery">
    <h1>Atrakcje</h1>

    <h3>Lotnisko w Szymanowie</h3>
    <figure class="figure">
      <img
        alt="lotnisko"
        src="/img/photos/atrakcje_szymanow.jpg"
        class="figure-img img-fluid rounded"
        style="width: 500px;"
      />
      <p>
        Lotnisko w Szymanowie oferuje loty widokowe, szkolenia i skoki ze spadochronem.
        Oferta na stronach <a href="https://www.aeroklub.wroc.pl/" target="_blank
">https://www.aeroklub.wroc.pl/</a> oraz <a href="https://skydive.wroclaw.pl/">https://skydive.wroclaw.pl/</a>
      </p>
    </figure>

    <h3>Strefa MTB Wysoki Kościół</h3>
    <figure class="figure">
      <img
          alt="mtb"
          src="/img/photos/mtb.jpg"
          class="figure-img img-fluid rounded"
          style="width: 500px;"
      />
      <p>
        Trasy rowerowe wzdłuż rzeki Widawy oraz po Wzgórzach Trzebnickich zostały oznaczone na mapie kolorem niebieskim i zielonym. W Wysokim Kościele znajduje się strefa MTB, na terenie której treningi i zawody organizuje OKSiR w Wiszni Małej.
      </p>
    </figure>

    <h3>Trasa kajakowa na rzece Widawa</h3>
    <figure class="figure">
      <img
          alt="mtb"
          src="/img/photos/atrakcje_kajak.jpg"
          class="figure-img img-fluid rounded"
          style="width: 500px;"
      />
      <p>
        Cztery miejscowości: Krzyżanowice, Psary, Szymanów i Szewce leżą nad rzeką Widawą, którą możemy spłynąć w kierunku Odry, a następnie do Miasteczka Portowego w Urazie. <br> Oferta dostępna na <a target="_blank" href="http://www.kayaktours.pl/rzeka-widawa-splywy-kajakowe/ ">http://www.kayaktours.pl/rzeka-widawa-splywy-kajakowe/</a> oraz <a href="http://miasteczkoportowe.pl/">http://miasteczkoportowe.pl/</a>
      </p>
    </figure>

    <h3>Kompleks Miłocin w Pierwoszowie</h3>
    <figure class="figure">
      <img
          alt="milocin"
          src="/img/photos/atrakcje_milocin.png"
          class="figure-img img-fluid rounded"
          style="width: 500px;"
      />
      <p>
        Miłocin to ośrodek wypoczynkowo – rekreacyjny z restauracją niedaleko Pierwoszowa. Obiekt znajduje się przy zielonym szlaku rowerowy, tuż przy stacji kolejowej. To idealne miejsce na piknik, wędkowanie lub weekend. <a href="http://www.milocin.com.pl/">http://www.milocin.com.pl/</a>
      </p>
    </figure>


    <h3>Stary Folwark w Machnicach</h3>
    <figure class="figure">
      <img
          alt="machnice"
          src="/img/photos/atrakcje_machnice.png"
          class="figure-img img-fluid rounded"
          style="width: 500px;"
      />
      <p>
        Malowniczy park i pałac są ozdobą miejscowości Machnice. W pobliżu pałacu znajduje się Stary Folwark – przestronny kompleks sportowo-rekreacyjny oferujący gościom bazę sportową, miejsca noclegowe, restaurację, sale bankietowe i konferencyjne. <a href="http://www.ptakmachnice.pl/stary-folwark.html" target="_blank">http://www.ptakmachnice.pl/stary-folwark.html</a>
      </p>
    </figure>

    <h3>
      Wiszniak - najwyższe wzniesienie w gminie z panoramą Wrocławia i okolic
    </h3>
    <figure class="figure">
      <img
          alt="wiszniak"
          src="/img/photos/wiszniak.jpg"
          class="figure-img img-fluid rounded"
          style="width: 500px;"
      />
      <p>
        Wiszniak (247,4 m n.p.m.) to najwyższe wzniesienie na terenie gminy Wisznia Mała położone w paśmie Wzgórz Trzebnickich (Kocie Góry). Do dyspozycji turystów na Wiszniaku zamontowano wiaty, stoły i ławki, miejsce do rozpalania ogniska oraz stojaki na rowery.
        <a href="www.facebook.com/wiszniak247" target="_blank">www.facebook.com/wiszniak247</a>
      </p>
    </figure>


    <h3>Stajnie w Wiszni Małej i Ozorowicach</h3>
    <figure class="figure">
      <img
        alt="stajnia"
        src="/img/photos/stajnia.jpg"
        class="figure-img img-fluid rounded"
        style="width: 500px;"
      />
      <p>
        Stajnie prowadzą zajęcia nauki jazdy konnej. Oferta dostępna jest na stronach:<br>
        
        Wisznia Mała: <a href="www.facebook.com/stajniasmallcherry" target="_blank">www.facebook.com/stajniasmallcherry</a>
        <br>
        Ozorowice: <a href="www.facebook.com/kjstajniaozorowice" target="_blank">www.facebook.com/kjstajniaozorowice</a>
        <br>
        Krzyżanowice: <a href="http://www.stajniakrzyzanowice.pl/" target="_blank">http://www.stajniakrzyzanowice.pl/</a>
        <br>
        Mienice: <a href="https://stajniagizynscy.pl/" target="_blank">https://stajniagizynscy.pl/</a>


      </p>
    </figure>


    <h3>Winnice w Mienicach i Malinie</h3>
    <figure class="figure">
      <img
        alt="lotnisko"
        src="/img/photos/winnica.jpg"
        class="figure-img img-fluid rounded"
        style="width: 500px;"
      />
      <p>
        Winnice znajdują się u podnóża Wzgórz Trzebnickich. Zapraszamy do skorzystania z oferty rodzinnych i ekologicznych winiarni:
        <a href="https://www.facebook.com/WinnicaJadwiga">https://www.facebook.com/WinnicaJadwiga</a>  oraz <a href="https://www.facebook.com/WinnicaMalin/">https://www.facebook.com/WinnicaMalin/</a>
      </p>
    </figure>

    <h3>Pole golfowe Toya-golf w Krynicznie</h3>
    <figure class="figure">
      <img
          alt="lotnisko"
          src="/img/photos/golf.jpg"
          class="figure-img img-fluid rounded"
          style="width: 500px;"
      />
      <p>
        Oferta dostępna na stronie <a href="www.Toya-golf.pl">www.Toya-golf.pl</a>
      </p>
    </figure>

    <h3>Restauracje i bary</h3>
    <figure class="figure">
      <img
          alt="psary"
          src="/img/photos/atrakcje_psary_pizza.png"
          class="figure-img img-fluid rounded"
          style="width: 500px;"
      />
      <p>Zapraszamy do skorzystania z oferty lokalnych restauracji:</p>
      <p>
        Psary: <a href="http://pizzburger.pl/" target="_blank">http://pizzburger.pl/</a> <br>
        Kryniczno: <a href="http://restauracjapodrodze.pl/" target="_blank">http://restauracjapodrodze.pl/</a> <br>
        Kryniczno: <a href="http://Italianjob.pl/" target="_blank">http://Italianjob.pl/</a> <br>
        Ligota Piękna: Karczma u Ani <br>
        Wisznia Mała: <a href="https://www.facebook.com/OazaRestauracja" target="_blank"> https://www.facebook.com/OazaRestauracja</a><br>
        Pierwoszów: <a href="http://www.milocin.com.pl/" target="_blank">http://www.milocin.com.pl/</a> <br>
      </p>
    </figure>
    <hr>

    <div class="footer">
      <div class="row">
        <div class="col-sm">
          Urząd Gminy Wisznia Mała <br>
          Ul. Wrocławska 9 <br>
          55-114 Wisznia Mała <br>
          Tel. 71 308 48 00 <br>
          71 312 70 25 <br>
        </div>
        <div class="col-sm">
          <br><br>
          e-mail: ug@wiszniamala.pl <br>
          <a href="www.wiszniamala.pl">www.wiszniamala.pl</a> <br>
          <a href="www.facebook.com/wiszniamala">facebook.com/wiszniamala</a>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
/* eslint-disable */

export default {
  head() {
    return {
      title: {
        inner: 'Atrakcje'
      },
      meta: [
        {
          name: 'description',
          content: `${this.appTitle} home page`,
          id: 'desc'
        }
      ]
    }
  },
  // computed: mapState('app', ['appTitle']),
  async mounted() {},
  components: {},
  data() {
    return {}
  },
  computed: {},
  methods: {},
  async created() {}
}
</script>
<style lang="scss">

</style>
