<template>
  <div>
    <b-navbar toggleable="lg" variant="info">
      <router-link to="/"
        ><img
          alt="logo-wm"
          class="logo"
          src="/img/wm.png"
          style="height: 70px;"
          href="/"
      /></router-link>
      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item href="/home">Mapa</b-nav-item>
          <b-nav-item href="/atrakcje">Atrakcje</b-nav-item>
          <b-nav-item href="/historia">Historia</b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  computed: {
    ...mapGetters('authentication', ['isUserLoggedIn']),
    ...mapState('authentication', ['user']),
    ...mapState('app', ['networkOnLine', 'appTitle', 'appShortTitle'])
  },
}
</script>

<style lang="scss" scoped>
@import '@/theme/variables.scss';

.navbar-collapse {
  background: #fff;
}

.navbar {
  position: relative;
  background: #fff !important;
  padding: 5px 24px;
  min-height: 90px;
  line-height: 2.2rem;
  z-index: 99999;
  .nav-item {
    margin-right: 15px;
    a {
      color: #628d80;
    }
  }
  .logo {
    height: 24px;
    padding-right: 8px;
  }
}
@media only screen and (min-width: 992px) {
  .navbar {
    min-height: 110px;
  }
}
</style>
