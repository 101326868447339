const granicagminy = [
  [16.93751, 51.27017],
  [16.96672, 51.27304],
  [16.97194, 51.27857],
  [16.97405, 51.28325],
  // [16.97528, 51.28055],
  // [16.97686, 51.28057],
  // [16.97667, 51.28255],
  // [16.9828, 51.28295],
  // [16.98171, 51.2852],
  // [16.98515, 51.28529],
  [16.98532, 51.28316],
  [16.99023, 51.28379],
  [17.00282, 51.28795],
  [17.01617, 51.28267],
  [17.02192, 51.28414],
  [17.03163, 51.28334],
  [17.03834, 51.27817],
  [17.05091, 51.27878],
  [17.05822, 51.28184],
  [17.06097, 51.2811],
  [17.06324, 51.28435],
  [17.06988, 51.28168],
  [17.07227, 51.27937],
  [17.07169, 51.2769],
  [17.0789, 51.27004],
  // [17.08116, 51.27172], // brochocin
  // [17.081, 51.27448],
  // [17.08301, 51.27435],
  [17.08271, 51.2695],
  [17.08469, 51.2632],
  [17.08282, 51.26165],
  [17.09069, 51.26029],
  [17.08995, 51.25908],
  [17.09542, 51.25902],
  [17.09961, 51.25513],
  [17.10832, 51.25643],
  // [17.11337, 51.2585],
  [17.11498, 51.2609],
  [17.11813, 51.25944],
  // [17.11587, 51.25886],
  [17.12049, 51.25878],
  [17.12346, 51.255],
  [17.11865, 51.24328],
  [17.09229, 51.24909],
  [17.08669, 51.241],
  [17.08042, 51.24166],
  [17.07549, 51.23754],
  [17.07284, 51.23292],
  // [17.07964, 51.22358],
  // [17.08694, 51.22375],
  [17.08078, 51.22096],
  [17.08747, 51.21432],
  [17.08989, 51.20405],
  [17.09399, 51.19742],
  [17.0929, 51.19455],
  [17.09835, 51.19399],
  [17.0969, 51.18636],
  [17.10029, 51.17586],
  [17.09818, 51.17632],
  [17.09812, 51.17438],
  [17.0845, 51.1768],
  [17.0827, 51.17496],
  [17.08008, 51.17524],
  [17.07764, 51.16595],
  [17.07974, 51.16423],
  [17.0784, 51.16279],
  [17.07241, 51.16597],
  [17.0654, 51.16492],
  [17.04237, 51.17353],
  [17.02713, 51.17546],
  [17.02411, 51.17903],
  [17.02634, 51.18095],
  [17.02248, 51.18586],
  [17.01813, 51.18577],
  [17.01456, 51.18764],
  [17.01346, 51.1857],
  [17.01492, 51.18262],
  [16.99993, 51.18184],
  [16.9992, 51.18769],
  [17.00084, 51.1915],
  [16.99904, 51.19383],
  [17.00091, 51.19747],
  [16.99163, 51.20239],
  [16.9796, 51.20548],
  [16.97304, 51.20441],
  [16.96704, 51.20544],
  [16.96634, 51.20763],
  [16.95872, 51.20931],
  [16.95, 51.22071],
  [16.93971, 51.22311],
  [16.94331, 51.22881],
  [16.94764, 51.22375],
  [16.95241, 51.22399],
  [16.95894, 51.23162],
  [16.95137, 51.24038],
  [16.94586, 51.24052],
  [16.95594, 51.24938],
  [16.96, 51.24963],
  [16.96061, 51.25503],
  [16.95989, 51.25955],
  [16.93931, 51.26168],
  [16.93751, 51.27017]
].map(latlng => [latlng[1], latlng[0]])
export default granicagminy;