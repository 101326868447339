<template>
  <div id="app">
    <nav-bar></nav-bar>
    <div class="main-wrapper">
      <router-view />
    </div>
    <div class="fixed-bottom" style="z-index: 100000">
      <center>
        <div v-if="showCookieDialog" class="alert-info" style="padding: 10px">
          Używamy plików cookies, by ułatwić korzystanie z naszych serwisów. Jeśli nie chcesz, by pliki cookies były zapisywane na Twoim dysku, zmień ustawienia swojej przeglądarki.
          <button @click="hideCookieDialog" type="button" class="close" aria-label="Close" style="float: none">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      </center>
    </div>
  </div>
</template>
<script>
import NavBar from '@/components/NavBar'
import { mapState, mapActions, mapGetters } from 'vuex'
import 'vueperslides/dist/vueperslides.css'

export default {
  components: {
    NavBar
  },
  computed: {
    ...mapGetters('app', ['newContentAvailable']),
    ...mapState('app', ['showAddToHomeScreenModalForApple', 'refreshingApp'])
  },
  data () {
    // console.log('data');
    return {
      // showCookieDialog: true
    }
  },
  methods: {
    ...mapActions('app', []),
    hideCookieDialog: () => {
      // console.log('hide');
      // console.log($this);
      this.showCookieDialog = false;
    },
  },
  created: () => {
    const appHeight = () => {
      const doc = document.documentElement
      doc.style.setProperty('--app-height', `${window.innerHeight}px`)
      // console.log('resize')
    }
    window.addEventListener('resize', appHeight)
    appHeight()
  }
}
</script>

<style lang="scss">

:root {
  --app-height: 100%;
}
html,
body {
  padding: 0;
  margin: 0;
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  height: var(--app-height);
}
body {
  padding: 0;
  margin: 0;

  min-height: 100vh;
  a {
    font-weight: 500;
    text-decoration: none;
  }
  #app {
    height: 100vh;
    height: var(--app-height);
    width: 100%;
    display: flex;
    flex-direction: column;
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
      Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 16px;
    color: #2c3e50;

    .main-wrapper {
      display: flex;
      flex: 1;

      overflow-y: auto;

      .static-content {
        overflow-y: auto;
      }

      @media only screen and (min-width: 992px) {
        .page-wrapper.gallery {
          width: 40% !important;
        }
      }

      .page-wrapper {
        text-align: center;
        width: 100%;

        flex-direction: column;
        //justify-content: center;
        align-items: center;
        .logo {
          margin-bottom: 3rem;
        }

        .home-page-title {
          text-align: center;
        }

        .documentation-link {
          display: inline-block;
          font-size: 1.2rem;
          color: #fff;
          background-color: #5d6788;
          padding: 0.8rem 1.6rem;
          border-radius: 4px;
          transition: background-color 0.1s ease;
          box-sizing: border-box;
          text-decoration: none;
          width: fit-content;
          font-weight: 500;
        }

        .photoMarker {
          position: absolute;
          border-radius: 50%;
          border: 3px solid #fff;
          width: 35px;
          height: 35px;
        }

        .photoMarker::after {
          position: absolute;
          content: '';
          width: 0px;
          height: 0px;
          bottom: -30px;
          left: -6px;
          border: 10px solid transparent;
          border-top: 17px solid #fff;
        }
      }
    }
  }
}
</style>
