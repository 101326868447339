import Vue from 'vue'
import Router from 'vue-router'
import Head from 'vue-head'
import Home from '@/views/Home'
import { isNil } from 'lodash'
import store from '@/store'
import Atrakcje from '@/views/Atrakcje'
import Historia from '@/views/Historia'
import Wycieczki from '@/views/Wycieczki'

Vue.use(Router)

/* If you don't know about VueHead, please refer to https://github.com/ktquez/vue-head */

Vue.use(Head, {
  complement: process.env.VUE_APP_TITLE
})

/* If you don't know about VueRouter, please refer to https://router.vuejs.org/ */

const
    router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/home',
      name: 'mapa',
      component: Home,
      meta: {
        authNotRequired: true
      }
    },
    {
      path: '/atrakcje',
      name: 'atrakcje',
      component: Atrakcje,
      meta: {
        authNotRequired: true
      }
    },
    {
      path: '/historia',
      name: 'historia',
      component: Historia,
      meta: {
        authNotRequired: true
      }
    },
    {
      path: '/wycieczki',
      name: 'wycieczki',
      component: Wycieczki,
      meta: {
        authNotRequired: true
      }
    },
    {
      path: '*',
      redirect: '/home'
    }
  ]
})

/**
 * Handle user redirections
 */
// eslint-disable-next-line consistent-return
router.beforeEach((to, from, next) => {
  if (
    !(to.meta && to.meta.authNotRequired) &&
    isNil(store.state.authentication.user)
  ) {
    const path =
      store.state.authentication.user === null ? '/login' : '/check-login'
    return next(`${path}?redirectUrl=${to.path}`)
  }
  next()
})

export default router
