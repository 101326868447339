import GenericDB from './generic-db'

// [Optional] Extend GenericDB if you want CRUD operations
export default class PointDB extends GenericDB {
  constructor() {
    super('point')
  }

  // Here you can extend NewsDB with custom methods
}
