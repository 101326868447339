import firebase from 'firebase/app'
import 'firebase/auth'

const config = {
  apiKey: 'AIzaSyApd_iFn9jJskZHeiwE0SGqNdXszMRVTTg',
  authDomain: 'maps-9406c.firebaseapp.com',
  projectId: 'maps-9406c',
  storageBucket: 'maps-9406c.appspot.com',
  messagingSenderId: '964788548939',
  appId: '1:964788548939:web:f8f126f7c6739e37d524ce',
  measurementId: 'G-6DV9QDWE87'
}

firebase.initializeApp(config)
